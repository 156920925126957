<template>
    <div>
        <!-- Header -->
        <div class="header bg-gradient-success py-7 py-lg-8 pt-lg-9">
            <b-container>
                <div class="header-body text-center mb-7">
                    <b-row class="justify-content-center">
                        <b-col xl="5" lg="6" md="8" class="px-5">
                            <h1 class="text-white">Reset your password</h1>
                            <p class="text-lead text-white">
                                Let's recover your account.
                            </p>
                        </b-col>
                    </b-row>
                </div>
            </b-container>
            <!-- <div class="separator separator-bottom separator-skew zindex-100">
                <svg
                    x="0"
                    y="0"
                    viewBox="0 0 2560 100"
                    preserveAspectRatio="none"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <polygon
                        class="fill-default"
                        points="2560 0 2560 100 0 100"
                    ></polygon>
                </svg>
            </div> -->
        </div>
        <!-- Page content -->
        <b-container class="mt--8 pb-5">
            <b-row class="justify-content-center">
                <b-col lg="5" md="7">
                    <b-card no-body class="bg-secondary border-0 mb-0">
                        <div class="row justify-content-center">
                            <div class="col-6 mt-5">
                                <img
                                    src="img/brand/logos/otinga.svg"
                                    class="img-fluid"
                                />
                            </div>
                        </div>

                        <b-card-body class="px-lg-5 py-lg-5">
                            <div
                                class="text-center text-muted mb-4"
                                v-if="!password_change_success"
                            >
                                <small>Please set up a new password.</small>
                            </div>
                            <validation-observer
                                v-slot="{ handleSubmit }"
                                ref="formValidator"
                            >
                                <b-form
                                    role="form"
                                    @submit.prevent="handleSubmit(onSubmit)"
                                >
                                    <base-input
                                        alternative
                                        class="mb-3"
                                        name="Password"
                                        :rules="{ required: true, min: 8 }"
                                        prepend-icon="ni ni-lock-circle-open"
                                        type="password"
                                        placeholder="New Password"
                                        v-model="model.password"
                                        v-if="!password_change_success"
                                    >
                                    </base-input>
                                    <base-input
                                        alternative
                                        class="mb-3"
                                        name="Password"
                                        :rules="{ required: true, min: 8 }"
                                        prepend-icon="ni ni-lock-circle-open"
                                        type="password"
                                        placeholder="Confirm Password"
                                        v-model="model.password2"
                                        v-if="!password_change_success"
                                    >
                                    </base-input>

                                    <div class="text-center">
                                        <div
                                            class="invalid-feedback"
                                            style="display: block"
                                            v-if="error_message"
                                        >
                                            {{ error_message }}
                                        </div>
                                        <base-button
                                            type="primary"
                                            :pill="true"
                                            native-type="submit"
                                            class="my-4"
                                            :loading="isLoading"
                                            v-if="!password_change_success"
                                            >Change Password</base-button
                                        >
                                        <div
                                            v-else
                                            class="text-center text-success m-5"
                                        >
                                            <div>
                                                <h1>
                                                    <i
                                                        class="fas fa-check display-1"
                                                    ></i>
                                                </h1>
                                            </div>
                                            <div>
                                                <h1 class="display-4">
                                                    Your password has been
                                                    successfully changed.
                                                </h1>
                                            </div>
                                        </div>
                                    </div>
                                </b-form>
                            </validation-observer>
                            <b-row class="mt-3">
                                <b-col cols="12" class="text-center">
                                    <router-link
                                        v-if="!password_change_success"
                                        to="/register"
                                        ><small
                                            >Create new account</small
                                        ></router-link
                                    >
                                </b-col>
                            </b-row>
                        </b-card-body>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>
import rest_api from "@/rest_api";
export default {
    name: "ResetPassword",
    data() {
        return {
            model: {
                password: "",
                password2: "",
            },
            isLoading: false,
            error_message: "",
            password_change_success: false,
        };
    },
    methods: {
        getUid() {
            return this.$route.query.uid;
            // let b64Uid = this.$route.query.uid;
            // if (b64Uid == null) {
            //     return 0;
            // } else {
            //     return Buffer.from(b64Uid, "base64").toString();
            // }
        },
        getToken() {
            return this.$route.query.token;
        },
        onSubmit() {
            if (this.model.password === this.model.password2) {
                this.isLoading = true;
                rest_api.user
                    .resetPassword(
                        this.getUid(),
                        this.getToken(),
                        this.model.password
                    )
                    .catch((err) => {
                        this.error_message =
                            "Unable to reset your account at this time, please try again later.";
                    })
                    .then((response) => {
                        this.isLoading = false;
                        this.password_change_success = true;
                        setTimeout(() => {
                            this.$router.push({ path: "login" });
                        }, 2000);
                    });
            } else {
                this.error_message = "The passwords do not match.";
            }
        },
    },
};
</script>
