<template>
    <div>
        <!-- Header -->
        <div class="header bg-gradient-success py-7 py-lg-8 pt-lg-9">
            <b-container class="container">
                <div class="header-body text-center">
                    <b-row class="justify-content-center">
                        <b-col xl="5" lg="6" md="8" class="px-5">
                            <img
                                src="img/brand/logos/otingaWhite.svg"
                                class="img-fluid"
                            />
                            <h1 class="text-white">Acceptable Use Policy</h1>
                            
                        </b-col>
                    </b-row>
                </div>
            </b-container>
            <!-- <div class="separator separator-bottom separator-skew zindex-100">
                <svg
                    x="0"
                    y="0"
                    viewBox="0 0 2560 100"
                    preserveAspectRatio="none"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <polygon
                        class="fill-default"
                        points="2560 0 2560 100 0 100"
                    ></polygon>
                </svg>
            </div> -->
        </div>
        <!-- Page content -->
        <b-container class="pb-5">
            <!-- Table -->
            <b-row class="justify-content-center">
                <b-col>

                    <h3>1. Introduction</h3>
                    <p>
                        Thank you for choosing Otinga. This Acceptable Use
                        Policy ("Policy") outlines the acceptable and prohibited
                        uses of our services. By using Otinga's services, you
                        agree to comply with this Policy. Failure to do so may
                        result in suspension or termination of your account.
                    </p>

                    <h3>2. Prohibited Activities</h3>
                    <p>
                        When using Otinga's services, you must not engage in or
                        attempt to engage in any of the following activities:
                    </p>
                    <ul>
                        <li>
                            <strong>a. Exploitation of Trial Periods:</strong>
                            Creating multiple accounts or employing any other
                            tactics to unduly exploit our trial periods or
                            offers. We monitor for repetitive sign-ups and
                            reserve the right to terminate accounts that violate
                            this provision.
                        </li>
                        <li>
                            <strong>b. Scraping:</strong> Using manual or
                            automated software, devices, or other processes to
                            "scrape" or harvest data from the services,
                            websites, or applications of Otinga.
                        </li>
                        <li>
                            <strong>c. Hacking:</strong> Unauthorized access,
                            use, probe, or scan of a system security or
                            authentication measure, data, or traffic. This
                            includes interference with the services to any user,
                            host, or network including mail bombing, flooding,
                            or attempts to overload a system.
                        </li>
                        <li>
                            <strong>d. Misuse of Services:</strong> Using our
                            services to distribute malware, malicious software,
                            or engage in phishing schemes.
                        </li>
                        <li>
                            <strong>e. Illegal Activities:</strong> Use of the
                            services for anything that's illegal, including but
                            not limited to, illegal gambling or fraud.
                        </li>
                        <li>
                            <strong>f. Spam:</strong> Sending unsolicited
                            messages, promotions, advertising, or solicitations
                            (like "spam").
                        </li>
                        <li>
                            <strong>g. Defamatory or Abusive Language:</strong>
                            Using the services as a means to transmit or post
                            defamatory, harassing, abusive, or threatening
                            language.
                        </li>
                        <li>
                            <strong>h. Intellectual Property:</strong>
                            Uploading, distributing, or transmitting any
                            copyrighted content, trademarks, or proprietary
                            information without obtaining the prior written
                            consent of the owner.
                        </li>
                    </ul>

                    <h3>3. Reporting Violations</h3>
                    <p>
                        If you come across any violations of this policy, please
                        report them to us immediately at
                        <a href="mailto:info@otinga.io">info@otinga.io</a>. We
                        will take appropriate action based on the nature and
                        severity of the violation.
                    </p>

                    <h3>4. Revisions to the Policy</h3>
                    <p>
                        Otinga reserves the right to modify or revise this
                        Acceptable Use Policy at any time. We will notify users
                        of any changes and it's their responsibility to review
                        the policy periodically.
                    </p>

                    <h3>5. Consequences of Violation</h3>
                    <p>
                        Violations of this policy may result in a range of
                        actions including, but not limited to:
                    </p>
                    <ul>
                        <li>Temporary suspension of service</li>
                        <li>Permanent termination of service</li>
                        <li>Legal action</li>
                    </ul>

                    <h3>6. Contact Us</h3>
                    <p>
                        For any questions or clarifications regarding this
                        Acceptable Use Policy, please reach out to us at
                        <a href="mailto:info@otinga.io">info@otinga.io</a>.
                    </p>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>
export default {
    name: "PrivacyPolicy",
};
</script>
<style></style>
